.date-picker {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  /* background-color: #f8f9fa; */
}

.date-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.date-item.selected {
  background-color: #000;
  color: #fff;
}

.date-item .day {
  font-size: 14px;
  margin-bottom: 5px;
}

.date-item .date {
  font-size: 16px;
}

/* ====================================scrollbar=================== */
.date-picker {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.date-item {
  display: inline-block;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.date-item.selected {
  background-color: #007bff;
  color: white;
}

.day {
  font-weight: bold;
}

.date {
  font-size: 1.2em;
}

/* ================================================================= */
.attendance-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.attendance-details p,
.attendance-summary p {
  margin: 5px 0;
}

.attendance-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.attendance-table th,
.attendance-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.attendance-table th {
  background-color: #f2f2f2;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.attendance-details {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.attendance-details th,
.attendance-details td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.attendance-details th {
  background-color: #f2f2f2;
}
.attendance-summary {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.attendance-summary th,
.attendance-summary td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.attendance-summary th {
  background-color: #f2f2f2;
}
